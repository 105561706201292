$(function () {
    $('.main-slider .slider').slick({
        autoplay: false,
        draggable: false,
        fade: true,
        speed: 1000,
        arrows: true,
        asNavFor: '.main-slider .slider-nav',
        prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-arrow-left" aria-hidden="true"></i></a>',
        nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-arrow-right" aria-hidden="true"></i></a>',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    fade: false,
                    dots: true,
                    speed: 500
                }
            }
        ]
    });

    var thumbCount = $('.main-slider .slider img').length;

    $('.main-slider .slider-nav').slick({
        slidesToShow: thumbCount,
        slidesToScroll: thumbCount,
        asNavFor: '.main-slider .slider',
        dots: false,
        arrows: false,
        focusOnSelect: true
    });

    $('.reference-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        arrows: true,
        prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.news-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        arrows: true,
        prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });


    $('.project-detail-slider .slider').slick({
        autoplay: false,
        draggable: false,
        fade: true,
        speed: 1000,
        arrows: true,
        asNavFor: '.project-detail-slider .slider-nav',
        prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-arrow-left" aria-hidden="true"></i></a>',
        nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-arrow-right" aria-hidden="true"></i></a>',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    fade: false,
                    dots: true,
                    speed: 500
                }
            }
        ]
    });

    var thumbCountProjectDetail = $('.project-detail-slider .slider img').length;

    $('.project-detail-slider .slider-nav').slick({
        slidesToShow: thumbCountProjectDetail,
        slidesToScroll: thumbCountProjectDetail,
        asNavFor: '.project-detail-slider .slider',
        dots: false,
        arrows: false,
        focusOnSelect: true
    });

    lightbox.option({
        'albumLabel': "Resim %1 - %2"
    })
});

$('.projects .navigation li a').click(function () {
    $('.projects .navigation li a').removeClass('active');
    $(this).addClass('active');
    $('.projects .row').hide();
    var targetProjects = $(this).data('target');
    $('.projects #'+targetProjects).show();
});


$('.menu-opener').click(function () {
   $('body').toggleClass('menu-opened');
});

$('.compress').click(function () {
    $(this).parents('.project-info').toggleClass('compressed');
});



var inputs = document.querySelectorAll( '.inputfile' );
Array.prototype.forEach.call( inputs, function( input )
{
    var label	 = input.nextElementSibling,
        labelVal = label.innerHTML;

    input.addEventListener( 'change', function( e )
    {
        var fileName = '';
        if( this.files && this.files.length > 1 )
            fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
        else
            fileName = e.target.value.split( '\\' ).pop();

        if( fileName )
            label.querySelector( 'span' ).innerHTML = fileName;
        else
            label.innerHTML = labelVal;
    });
});